<template>
  <div>
    <recess-divider
      :display-medium-viewport="false"
      :display-small-viewport="false"
    />
    <transition name="fade">
      <loader-component
        v-if="isFetching"
        overlay="white"
        color="primary"
      />
    </transition>

    <p class="p-0 pl-md-4 col-12 col-md-8">
      Op deze pagina ziet u al de aanvragen buiten catalogus waarvoor u goedkeurder bent. De status geeft de actuele status weer.
      Aan de actieknop (rechts van de aanvraag) ziet u of u nog een actie dient uit te voeren.
    </p>

    <transition name="fade">
      <template v-if="requestsOutsideAssortedProducts && requestsOutsideAssortedProducts.length">
        <recess-table>
          <recess-table-head>
            <recess-table-row>
              <recess-table-head-item>Medewerker</recess-table-head-item>
              <recess-table-head-item>Ontwikkelactiviteit</recess-table-head-item>
              <recess-table-head-item>Status</recess-table-head-item>
              <recess-table-head-item>Prijs per persoon</recess-table-head-item>
              <recess-table-head-item />
            </recess-table-row>
          </recess-table-head>
          <recess-table-body>
            <recess-table-row
              v-for="courseRequest in requestsOutsideAssortedProducts"
              :key="courseRequest.id"
            >
              <recess-table-cell data-label="Medewerker">
                <span>{{ courseRequest.requestedByFullName }}</span>
              </recess-table-cell>
              <recess-table-cell data-label="Ontwikkelactiviteit">
                <span>{{ courseRequest.courseName }}</span>
              </recess-table-cell>
              <recess-table-cell data-label="Status">
                <span>{{ courseRequest.displayStatus }}</span>
              </recess-table-cell>
              <recess-table-cell data-label="Prijs per persoon">
                <span>{{ courseRequest.coursePrice ? formatPrice(courseRequest.coursePrice) : "-" }}</span>
              </recess-table-cell>
              <recess-table-cell>
                <recess-button
                  :title="courseRequest.status === requestOutsideAssortedProductStatus.PENDINGAPPROVAL ? 'Beoordelen' : 'Bekijk'"
                  :variant="courseRequest.status === requestOutsideAssortedProductStatus.PENDINGAPPROVAL ? 'primary' : 'tertiary'"
                  class="col-12 col-md-auto"
                  :url="{ name: 'course-request' , params: {id: courseRequest.id} }"
                />
              </recess-table-cell>
            </recess-table-row>
          </recess-table-body>
        </recess-table>
      </template>
    </transition>

    <recess-alert
      v-if="showNoResultsMessage"
      text="Er zijn op dit moment geen aanvragen buiten catalogus."
    />
    <recess-alert
      v-if="showErrorMessage"
      type="error"
      text="Er is iets misgegaan. Probeer het later nog een keer."
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import requestsOutsideAssortedProductsClient from '../../../api/requestOutsideAssortedProductsClient'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import priceHelper from '../../../modules/priceFormatter'
import * as requestOutsideAssortedProductStatus from '../../../constants/requestOutsideAssortedProductStatus'
import ODataFilterOperations from '../../../api/OData/odataFilterOperations'
import ODataQueryBuilder from '../../../api/OData/odataQueryBuilder'
import ODataQueryFilter from '../../../api/OData/odataQueryFilter'

export default {
    components: {
        LoaderComponent
    },
    data() {
        return {
            isFetching: false,
            showErrorMessage: false,
            requestsOutsideAssortedProducts: [],
            requestOutsideAssortedProductStatus
        }
    },
    computed: {
        ...mapGetters('userModule', ['companyTeamleadersIds']),
        showNoResultsMessage() {
            return (
                !this.requestsOutsideAssortedProducts?.length &&
                !this.isFetching &&
                !this.showErrorMessage
            )
        }

    },
    created() {
        this.getRequestsOutsideAssortedProducts()
    },
    methods: {
        async getRequestsOutsideAssortedProducts() {
            try {
                this.isFetching = true
                const oDataBaseQuery = new ODataQueryBuilder()
                    .setPagination(0, 9999)
                    .addFilter(
                        new ODataQueryFilter(
                            'requestedBy/companyId',
                            ODataFilterOperations.IN,
                            this.companyTeamleadersIds.join(', ')
                        ))
                    .addFilter(
                        new ODataQueryFilter(
                            'requestedBy/isDeleted',
                            ODataFilterOperations.EQUALS,
                            false
                        )
                    )
                    .addDynamicFilter('assignedApproverId ne null')
                    .setFields('requestedBy.firstName, requestedBy.middleName, requestedBy.lastName, requestedBy.companyId, courseName, id, status, coursePrice')
                    .build()

                const response = await requestsOutsideAssortedProductsClient.searchRequestsOutsideAssortedProducts(oDataBaseQuery)

                this.requestsOutsideAssortedProducts = response?.items
            } catch (err) {
                this.showErrorMessage = true
                throw new Error(err)
            } finally {
                this.isFetching = false
            }
        },
        formatPrice: priceHelper.formatPrice
    }
}
</script>

